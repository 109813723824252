<template>
  <div class="orderTest-container">
    <div class="upPic">
      <el-upload
        v-show="(!isEdit && isEdit_) || !showImgList"
        :action="baseURL + '/baseAdmin/common/upload-image'"
        list-type="picture-card"
        name="files"
        accept=".png, .jpg, .jpeg, .gif, .bmp, .jfif"
        :headers="headers"
        :before-upload="loadBefore"
        :on-success="onloadSucc"
        :on-error="onloadError"
        :on-change="onChange"
        :file-list="fileList"
        :disabled="isEdit"
        :limit="maxImg"
      >
        <i class="el-icon-plus"></i>
        <div slot="file" slot-scope="{ file }">
          <div v-if="file.is_default == '1'" v-show="showDefault" class="picD">
            默认
          </div>
          <!-- <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" /> -->
          <el-image fit="fill" :src="file.url"></el-image>
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              v-show="!isEdit && showDefault"
              class="el-upload-list__item-preview"
              @click="setDefault(file)"
            >
              <i class="el-icon-picture-outline"></i>
            </span>
            <span
              v-if="!disabled"
              v-show="!isEdit"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
      <div v-show="showImgList" class="df">
        <div v-for="(file, index) in fileList" :key="index">
          <!-- <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" /> -->
          <div style="position: relative; margin-left: 10px">
            <el-image
              style="width: 200px; height: 200px"
              fit="fill"
              :src="file.url"
              :preview-src-list="picUrl"
            ></el-image>
            <span v-if="file.is_default == '1' && showDefault" class="picD">
              默认
            </span>
          </div>
        </div>
      </div>
      <el-dialog
        :modal="false"
        top="5vh"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
      >
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
    <!-- <div class="tip">
      <el-alert
        title="1、本地上传图片大小不能超过5MB"
        type="warning"
      ></el-alert>
      <el-alert
        title="2、上传图片支持gif、jpg、png、jpeg、bmp、jfif"
        type="warning"
      ></el-alert>
    </div> -->
  </div>
</template>
<script>
  import { baseURL } from '@/config'
  export default {
    name: 'UploadImg',
    components: {},
    props: {
      alldata: {
        type: Object,
        default: () => {},
      },
      isEdit: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      showImgList: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      showDefault: {
        type: Boolean,
        default: () => {
          return true
        },
      },
      // 默认图片
      defaultImg: {
        type: Array,
        default: () => [],
      },
      // 默认最大上传5个图片
      maxImg: {
        type: Number,
        default: () => {
          return 5
        },
      },
      imgSize: {
        type: Number,
        default: 5242880,
      },
    },
    data() {
      return {
        mineData: null,
        editor: null,
        editorData: '',
        disabled: false,
        dialogImageUrl: '',
        dialogVisible: false,
        headers: { token: '' },
        fileList: [],
        content: '',
      }
    },
    computed: {
      baseURL() {
        return baseURL
      },
      picUrl() {
        let arr = []
        this.fileList.forEach((item) => {
          arr.push(item.url)
        })
        return arr
      },
      imgSizeMB() {
        let a = this.imgSize + ''
        return a.slice(0, 1)
      },
      isEdit_() {
        if (this.fileList.length >= this.maxImg) {
          return false
        } else {
          return true
        }
      },
    },
    watch: {
      defaultImg(v, n) {
        console.log('www', v, n)
        if (v) {
          if (v[0] != '' && v[0] != n[0]) {
            let a = []
            console.log('v', v)
            v.forEach((pic) => {
              a.push({
                url: pic,
              })
            })
            this.fileList = a
          }
        }
      },
    },
    created() {
      this.headers.token = this.$store.getters['user/accessToken']
    },
    mounted() {},
    methods: {
      // 设置默认图片
      setDefault(file) {
        this.fileList.forEach((item, index) => {
          this.fileList[index].is_default = '0'
          if (item.uid == file.uid) {
            this.fileList[index].is_default = '1'
          }
        })
        console.log(this.fileList)
      },
      handleRemove(file) {
        console.log(file)
        console.log(this.fileList)
        this.fileList.forEach((item, index) => {
          if (item.uid == file.uid) {
            this.fileList.splice(index, 1)
          }
        })
      },
      handlePictureCardPreview(file) {
        console.log('放大')
        console.log(file)
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      // 上传状态改变
      onChange(file, fileList) {
        console.log('上传状态改变 的钩子')
        // console.log(file)
        // console.log(fileList)
      },
      // 上传前
      loadBefore(file) {
        console.log('上传前 的钩子')
        console.log(file)
        if (file.size > this.imgSize) {
          this.$message.error(
            '本地上传图片大小不能超过' + this.imgSizeMB + 'MB'
          )
          return false
        }
        if (
          file.type == 'image/png' ||
          file.type == 'image/jpeg' ||
          file.type == 'image/jpg' ||
          file.type == 'image/gif' ||
          file.type == 'image/bmp'
        ) {
        } else {
          this.$message.error('上传图片支持gif、jpg、png、jpeg、bmp、jfif')
          return false
        }
      },
      // 上传时 的钩子
      onloadEvent(res, file, fileList) {
        // console.log('上传时 的钩子')
        // console.log(res)
        // console.log(file)
        // console.log(fileList)
      },
      // 上传成功
      onloadSucc(res, file, fileList) {
        console.log('上传成功时 的钩子')
        console.log(res)
        console.log(file)
        console.log(fileList)
        if (res.code == 200) {
          this.fileList = fileList
          this.fileList[this.fileList.length - 1].url = res.data[0].url
          this.fileList[this.fileList.length - 1].info = res.data[0].name
          this.fileList[this.fileList.length - 1].type = file.raw.type
          this.fileList[this.fileList.length - 1].is_default = '0'
          if (fileList.length == 1) {
            fileList[0].is_default = '1'
          }
          console.log(this.fileList)
        } else {
          console.log(this.fileList)
          this.$message.error(res.msg.files)
          return false
        }
      },
      // 上传失败
      onloadError(err, file, fileList) {
        console.log('上传失败 的钩子')
        console.log(err)
        console.log(file)
        console.log(fileList)
        this.$message.error('上传图片失败，请重试')
        return false
      },
      clearPic() {
        console.log('清空图片')
        this.fileList = []
      },
    },
  }
</script>

<style lang="scss" scoped>
  .upPic {
    margin: 15px 0;
  }
  ::v-deep {
    * {
      transform: none;
      animation: none;
      transition: none;
    }
    .el-upload--picture-card {
      width: 200px;
      height: 200px;
      line-height: 200px;
    }
  }
  .el-upload-list__item {
    margin: 0 20px 8px 0;
  }
  .upPic {
    margin: 0;
  }
  .el-alert {
    margin: 0;
  }
  .el-dialog__wrapper {
    z-index: 9999999999 !important;
  }
  .picD {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    float: right;
    width: 35px;
    color: #fff;
    text-align: center;
    content: '';
    background: rgb(84, 85, 84, 0.5);
    border-radius: 20px;
  }
  .df {
    display: flex;
    justify-content: flex-start;
  }
  ::v-deep {
    .el-upload-list--picture-card .el-upload-list__item {
      width: 200px;
      height: 200px;
    }
  }
</style>
