var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderTest-container" }, [
    _c(
      "div",
      { staticClass: "upPic" },
      [
        _c(
          "el-upload",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: (!_vm.isEdit && _vm.isEdit_) || !_vm.showImgList,
                expression: "(!isEdit && isEdit_) || !showImgList",
              },
            ],
            attrs: {
              action: _vm.baseURL + "/baseAdmin/common/upload-image",
              "list-type": "picture-card",
              name: "files",
              accept: ".png, .jpg, .jpeg, .gif, .bmp, .jfif",
              headers: _vm.headers,
              "before-upload": _vm.loadBefore,
              "on-success": _vm.onloadSucc,
              "on-error": _vm.onloadError,
              "on-change": _vm.onChange,
              "file-list": _vm.fileList,
              disabled: _vm.isEdit,
              limit: _vm.maxImg,
            },
            scopedSlots: _vm._u([
              {
                key: "file",
                fn: function (ref) {
                  var file = ref.file
                  return _c(
                    "div",
                    {},
                    [
                      file.is_default == "1"
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showDefault,
                                  expression: "showDefault",
                                },
                              ],
                              staticClass: "picD",
                            },
                            [_vm._v(" 默认 ")]
                          )
                        : _vm._e(),
                      _c("el-image", { attrs: { fit: "fill", src: file.url } }),
                      _c(
                        "span",
                        { staticClass: "el-upload-list__item-actions" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "el-upload-list__item-preview",
                              on: {
                                click: function ($event) {
                                  return _vm.handlePictureCardPreview(file)
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-zoom-in" })]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isEdit && _vm.showDefault,
                                  expression: "!isEdit && showDefault",
                                },
                              ],
                              staticClass: "el-upload-list__item-preview",
                              on: {
                                click: function ($event) {
                                  return _vm.setDefault(file)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-picture-outline",
                              }),
                            ]
                          ),
                          !_vm.disabled
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isEdit,
                                      expression: "!isEdit",
                                    },
                                  ],
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(file)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          },
          [_c("i", { staticClass: "el-icon-plus" })]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showImgList,
                expression: "showImgList",
              },
            ],
            staticClass: "df",
          },
          _vm._l(_vm.fileList, function (file, index) {
            return _c("div", { key: index }, [
              _c(
                "div",
                {
                  staticStyle: { position: "relative", "margin-left": "10px" },
                },
                [
                  _c("el-image", {
                    staticStyle: { width: "200px", height: "200px" },
                    attrs: {
                      fit: "fill",
                      src: file.url,
                      "preview-src-list": _vm.picUrl,
                    },
                  }),
                  file.is_default == "1" && _vm.showDefault
                    ? _c("span", { staticClass: "picD" }, [_vm._v(" 默认 ")])
                    : _vm._e(),
                ],
                1
              ),
            ])
          }),
          0
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              modal: false,
              top: "5vh",
              "close-on-click-modal": false,
              visible: _vm.dialogVisible,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c("img", {
              attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }